<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-header color="default">查詢</mdb-card-header>
            <mdb-card-body class="pt-0">
                <mdb-row>
                    <mdb-col md="3">
                        <mdb-select label="分店"
                                    v-model="storeList"
                                    @getValue="val=>search.id_store=val"></mdb-select>
                    </mdb-col>
                    <mdb-col md="3">
                        <datepicker label="報廢日期(起)"
                                    v-model="search.start"
                                    :max="new Date()"></datepicker>
                    </mdb-col>
                    <mdb-col md="3">
                        <datepicker label="報廢日期(訖)"
                                    v-model="search.end"
                                    :min="search.start"
                                    :max="new Date()"></datepicker>
                    </mdb-col>
                    <mdb-col md="3"
                             class="pt-3 text-sm-right text-md-left">
                        <mdb-btn color="default"
                                 size="sm"
                                 class="mt-3"
                                 @click="search.page=1;searchdata()">
                            <i class="fa fa-search"></i>
                            搜&nbsp;&nbsp;尋
                        </mdb-btn>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-card>
            <mdb-card-body>

                <table class="table table-striped rwd-table-dispersion">
                    <!--Table head-->
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>報廢人</th>
                            <th>報廢日期</th>
                            <th>分店</th>
                            <th></th>
                        </tr>
                    </thead>
                    <!--Table head-->
                    <!--Table body-->
                    <tbody>
                        <tr v-for="(nb,nbk) in data.new_badList"
                            :key="`nb_${nb.id}`">
                            <th data-title="#">
                                {{(data.nowPage-1)*10+nbk+1}}
                            </th>
                            <td data-title="報廢人">
                                {{adminKeyList[nb.id_admin] ? adminKeyList[nb.id_admin].name : ''}}
                            </td>
                            <td data-title="報廢日期">{{nb.day}}</td>
                            <td data-title="分店">
                                {{storeKeyList[nb.id_store] ? storeKeyList[nb.id_store].name : ''}}
                            </td>

                            <td class="text-right text-md-left">
                                <mdb-btn color="primary"
                                         size="sm"
                                         @click="$router.push(`/product/new_bad/view/${nb.id}`)">
                                    <i class="fa fa-eye"></i>檢視
                                </mdb-btn>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="7"
                                scope="row"
                                v-show="data.new_badList.length==0">無資料</td>
                        </tr>
                    </tbody>
                    <!--Table body-->
                </table>
                <page :nowPage="data.nowPage"
                      :totalPage="data.totalPage"
                      @switchpage="pg=>{search.page=pg;searchdata();}" />
            </mdb-card-body>
        </mdb-card>
    </div>
</template>
<script>
import {
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbRow,
  mdbCol,
  // mdbInput,
  mdbBtn,
  mdbSelect,
} from "mdbvue";
import page from "../../components/page";
import datepicker from "../../components/datepicker";
export default {
  props: { query: { default: {} } },
  components: {
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    // mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbSelect,
    page,
    datepicker,
  },
  data() {
    return {
      search: {
        start: "",
        end: "",
        id_store: "",
        page: 1,
      },
      adminList: [],
      storeList: [],
      search_change: false,
      data: {
        new_badList: [],
        nowPage: 1,
        totalPage: 0,
      },
    };
  },
  mounted() {
    let vue = this;
    vue.put_search();
  },
  watch: {
    search: {
      handler() {
        this.search_change = true;
      },
      deep: true,
    },
    query() {
      this.put_search();
    },
  },
  computed: {
    storeKeyList() {
      let output = {};
      this.storeList.forEach((item) => {
        output[item.id] = item;
      });
      return output;
    },
    adminKeyList() {
      let output = {};
      this.adminList.forEach((item) => {
        output[item.id] = item;
      });
      return output;
    },
  },
  methods: {
    put_search() {
      let vue = this;
      // 把篩選的資料放到變數裡面
      for (let i in vue.search) {
        vue.search[i] = vue.query[i] ? vue.query[i] : "";
      }
      vue.search.page = vue.query.page ? vue.query.page : 1;
      vue.getData();
    },
    // 搜尋資料
    searchdata() {
      let vue = this;
      if (vue.search_change) {
        let url = vue.$route.path + "?";
        for (let k in vue.search) {
          url += `${k}=${vue.search[k]}&`;
        }
        vue.$router.push(url);
      }
      vue.search_change = false;
    },
    getData() {
      let vue = this,
        query = [
          {
            name: "new_bad",
            data: {},
            pg: vue.search.page == "" ? 1 : vue.search.page,
            sort: {
              day: 1,
            },
          },
          {
            name: "new_bad",
            data: {},
            num: "",
          },
          {
            name: "store",
            data: {
              active: {
                type: 0,
                value: 1,
              },
            },
            sort: {
              name: 1,
            },
          },
          {
            name: "admin",
            data: {},
          },
        ];
      if (vue.search.id_store != "") {
        query[0].data.id_store = { type: 0, value: vue.search.id_store };
      }
      switch (true) {
        case vue.search.start != "" && vue.search.end != "":
          query[0].data.day = {
            type: 7,
            value: [vue.search.start, vue.search.end],
          };
          break;
        case vue.search.start != "" && vue.search.end == "":
          query[0].data.day = {
            type: 5,
            value: vue.search.start,
          };
          break;
        case vue.search.start == "" && vue.search.end != "":
          query[0].data.day = {
            type: 4,
            value: vue.search.end,
          };
          break;
      }
      query[1].data = query[0].data;
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${JSON.stringify(query)}`,
          },
        })
        .then((res) => {
          res.data.storeList.unshift({ id: "", name: "全部" });
          vue.storeList = res.data.storeList.map((item) => {
            item.value = item.id;
            item.text = item.name;
            item.selected = vue.$store.state.id_store == item.id;
            return item;
          });
          vue.adminList = res.data.adminList;
          vue.data.new_badList = res.data.new_badList;
          vue.data.totalPage = Math.ceil(res.data.new_badNumber / 10);
          vue.data.nowPage = vue.search.page;
        });
    },
  },
};
</script>